import React from "react"
import Layout from "../components/Layout"

const product = () => {
  return (
    <Layout
      title="Page not found"
      description="There was an error: page not found"
      contentType="website"
    >
      Oops. This is dead end
    </Layout>
  )
}

export default product
